import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import SignIn from "../pages/SignIn";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import Verify from "../pages/Verify";

import { AuthContext } from "../context/AuthContext";
// import Register from "../pages/Register";
import VerifyAccount from "../pages/VerifyAccount";

export default function NonAuthRoutes() {
  const auth = useContext(AuthContext);

  const renderPage = (page) => {
    if (auth.token) return <Navigate to="/dashboard" />;
    return page;
  };

  return (
    <Routes>
      <Route path="/" element={renderPage(<SignIn />)} />
      {/* <Route path="/register" element={renderPage(<Register />)} /> */}
      <Route path="/forgot-password" element={renderPage(<ForgotPassword />)} />
      <Route path="/reset-password" element={renderPage(<ResetPassword />)} />
      <Route path="/verify-2fa/:userId" element={renderPage(<Verify />)} />
      <Route path="/verify" element={renderPage(<VerifyAccount />)} />
    </Routes>
  );
}
