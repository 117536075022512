import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import axios from "../../axios";

const Form = () => {
  const initialState = {
    wallet: { value: "", error: "" },
    code: { value: "", error: "" },
  };

  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const auth = useContext(AuthContext);

  useEffect(() => {
    axios.get(`/user/profile?id=${auth.userId}&currency=eur`,
      {
        headers: { Authorization: `Bearer ${auth.token}`, "special-token": `Bearer ${auth.specialToken}` },
      }
    ).then(res => {
      const wallet = res.data?.wallet;
      setState({ ...state, wallet: { value: wallet, error: "" } })
    });

  }, []);

  const clearErrors = () => {
    const updatedState = { ...state };
    for (const item in updatedState) {
      updatedState[item].error = "";
    }
    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);
    clearErrors();

    try {
      const data = {
        wallet: state.wallet.value,
        code: state.code.value,
      };
      await axios.patch("/user/wallet", data, {
        headers: { Authorization: `Bearer ${auth.token}`, "special-token": `Bearer ${auth.specialToken}` },
      });
      setSuccess(true);
      setState({ ...initialState, wallet: { value: data.wallet, error: "" } });
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Něco se pokazilo"]);
      }
    }
    setLoading(false);
  };

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  return (
    <form style={{ maxWidth: "573px" }} className="mb-8" onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              placeholder="Vložte Vaši MetaMask adresu. . ."
              value={state.wallet.value}
              onChange={e => handleInput("wallet", e.target.value)}
            />
            <small className="text-danger block mt-1">
              {state.wallet.error}
            </small>
          </div>
        </div>
      </div>

      <div className="form-group">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              value={state.code.value}
              onChange={e => handleInput("code", e.target.value)}
              placeholder="2FA"
            />
            <small className="text-danger block mt-1">
              {state.code.error}
            </small>
          </div>

        </div>
      </div>

      <div className="btn-list ml-auto text-right">
        <button
          style={{ width: "150px" }}
          type="submit"
          className="btn btn-primary mb-0"
          disabled={loading}
        >
          Uložit
        </button>
        {success && (
          <small className="text-success block mt-2">
            Kredit byl úspěšně přidán
          </small>
        )}
        {errors &&
          errors.map((error, i) => (
            <small key={`error-${i}`} className="text-danger block mt-2 font-small">
              {error}
            </small>
          ))}
      </div>
    </form>
  )
}

export default Form
