import React, { useEffect } from "react";
import TableItem from "./TableItem";
import { Link } from "react-router-dom";
import styles from "./Popup.module.css";

export default function ClientList({ clients, isSeller }) {
  const initDatatable = () => {
    window.$("#oldClients").DataTable({
      lengthChange: false,
      responsive: false,
      pageLength: 5,
      language: {
        searchPlaceholder: "Hledat...",
        sSearch: "",
        lengthMenu: "_MENU_ ",
      },
    });

    window.$("#newClients").DataTable({
      lengthChange: false,
      responsive: false,
      pageLength: 5,
      language: {
        searchPlaceholder: "Hledat...",
        sSearch: "",
        lengthMenu: "_MENU_ ",
      },
    });

    window.$("#sellers").DataTable({
      lengthChange: false,
      responsive: false,
      language: {
        searchPlaceholder: "Hledat...",
        sSearch: "",
        lengthMenu: "_MENU_ ",
      },
    });
  };

  useEffect(() => {
    initDatatable();
  }, []);

  const oldClients = clients.filter(itm => itm.deposit !== 0 || itm.capital !== 0 || itm.profit !== 0 || isSeller && !itm.isSeller);
  const sellerClients = clients.filter(itm => itm.isSeller);
  const newClients = clients.filter(itm => itm.deposit === 0 && itm.capital === 0 && itm.profit === 0 && !itm.isSeller);

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title" style={{ textTransform: "none" }}>Aktuální klienti</h3>
          </div>
          <div className="card-body" style={{ marginTop: "-55px" }}>
            <div className="e-table">
              <div className="table-responsive table-lg">
                <table
                  className="table table-vcenter text-nowrap border mb-0"
                  id="oldClients"
                >
                  <thead>
                    <tr>
                      <th>Celé jméno</th>
                      <th>Vklad</th>
                      <th>Začátek</th>
                      <th>Konec</th>
                      <th>Zisk</th>
                      <th>Upravit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {oldClients.map((client) => (
                      <TableItem key={client.id} {...client} />
                    ))}
                  </tbody>
                </table>

              </div>
            </div>

            {!isSeller && <Link to="/sign-up/user" className={`btn btn-primary ${styles.fixupMargin}`}>
              <i className="fe fe-plus"></i> Přidat klienta
            </Link>}
          </div>
        </div>
      </div>


      {!isSeller && <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title" style={{ textTransform: "none" }}>Všichni obchodníci</h3>
          </div>
          <div className="card-body" style={{ marginTop: "-55px" }}>
            <div className="e-table">
              <div className="table-responsive table-lg">
                <table
                  className="table table-vcenter text-nowrap border mb-0"
                  id="sellers"
                >
                  <thead>
                    <tr>
                      <th>Celé jméno</th>
                      <th>Vklad</th>
                      <th>Začátek</th>
                      <th>Konec</th>
                      <th>Zisk</th>
                      <th>Upravit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sellerClients.map((client) => (
                      <TableItem key={client.id} {...client} />
                    ))}
                  </tbody>
                </table>

              </div>
            </div>

            {<Link to="/sign-up/seller" className={`btn btn-primary ${styles.fixupMargin}`}>
              <i className="fe fe-plus"></i> Zaregistrovat obchodníka
            </Link>}
          </div>
        </div>
      </div>}

      {!isSeller && <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title" style={{ textTransform: "none" }}>
              Noví klienti
            </h3>
          </div>
          <div className="card-body" style={{ marginTop: "-55px" }}>
            <div className="e-table">
              <div className="table-responsive table-lg">
                <table
                  className="table table-vcenter text-nowrap border mb-0"
                  id="newClients"
                >
                  <thead>
                    <tr>
                      <th>Celé jméno</th>
                      <th>Vklad</th>
                      <th>Začátek</th>
                      <th>Konec</th>
                      <th>Zisk</th>
                      <th>Upravit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {newClients.map((client) => (
                      <TableItem key={client.id} {...client} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}
