import axios from "../../axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function VerifyAccount() {
  const [status, setStatus] = useState("loading")
  const [p] = useSearchParams();
  const token = p.get('token');

  const verify = async (token) => {
    try {
      await axios.post(`/user/verify?token=${token}`)
      setStatus("success")
    } catch (err) {
      setStatus("error")
    }
  }

  useEffect(() => {
    verify(token);
  }, [token]);

  return (
    <div className="page login-container">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div
                    className="card"
                    style={{
                      boxShadow:
                        "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0) 0px -3px 0px inset",
                      backgroundColor: "#062d51",
                      borderRadius: "25px",
                      border: "1px solid rgb(0 0 0)",
                    }}
                  >
                    <div className="card-body">
                      {status === "loading" && <div className="text-center pt-4">
                        <h1>Ověřování...</h1>
                      </div>
                      }
                      {status === "error" && <div className="text-center pt-4">
                        <h1>Došlo k chybě</h1>
                        <p>Token je buď neplatný, nebo jeho platnost vypršela</p>
                      </div>
                      }
                      {status === "success" && <div className="text-center pt-4">
                        <h1
                          className="font-weight-normal"
                          style={{ color: "#fff",  }}
                        >
                          <Link
                            to="/"
                            className="btn-link font-weight-normal"
                            style={{
                              color: "#fff",
                              textDecoration: "none",
                            }}
                          >Přihlaste se zde</Link>
                        </h1>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
