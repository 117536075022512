import React, { useRef } from "react";
import useForm from "../../hooks/useForm";

export default function ChangePassword(props) {
  const initialState = {
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
    checked: { value: false, error: "" },
  };

  const {
    state,
    setState,
    loading,
    success,
    errors,
    handleInput,
    handleSubmit,
  } = useForm(initialState);

  const checkboxRef = useRef();

  const customHandler = async (e) => {
    e.preventDefault();
    // clear errors
    const uState = { ...state };
    for (const itm in uState) {
      uState[itm].error = "";
    }
    setState(uState);

    if (state.password.value !== state.confirmPassword.value) {
      const updatedState = { ...state };
      updatedState.confirmPassword.error = "Hesla se neshodují";
      return setState(updatedState);
    }

    const result = await handleSubmit(e, "/user/change-password", "put", {
      userId: props.userId,
    });
    if (result !== false) {
      checkboxRef.current.checked = false;
      setState({ ...initialState });
    }
  };

  return (
    <div className="col-lg-12 col-xl-6 col-md-12 col-sm-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title" style={{ textTransform: "none" }}>
            Změna hesla
          </h4>
        </div>
        <div className="card-body">
          <form onSubmit={customHandler}>
            <div className="">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Nové heslo
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Zadejte heslo"
                  value={state.password.value}
                  onChange={(e) => handleInput("password", e.target.value)}
                />
                <small className="text-danger block mt-1">
                  {state.password.error}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Potvrdit heslo
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Zadejte heslo"
                  value={state.confirmPassword.value}
                  onChange={(e) =>
                    handleInput("confirmPassword", e.target.value)
                  }
                />
                <small className="text-danger block mt-1">
                  {state.confirmPassword.error}
                </small>
              </div>
              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="example-checkbox2"
                  value={state.checked.value}
                  onChange={(e) => handleInput("checked", e.target.checked)}
                  ref={checkboxRef}
                />
                <span className="custom-control-label">
                  Potvrzuji změnu hesla.
                </span>
              </label>
              <small className="text-danger block mt-1">
                {state.checked.error}
              </small>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-0"
              disabled={loading}
            >
              {loading ? "Mění se..." : "Změnit"}
            </button>
            {success && (
              <small className="text-success block mt-2">
                Kredit byl úspěšně stažen
              </small>
            )}
            {errors &&
              errors.map((error, i) => (
                <small key={`error-${i}`} className="text-danger block mt-2">
                  {error}
                </small>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}
