import React, { useContext } from "react";
import { InfoContext } from "../../context/InfoContext";
// import { SidebarContext } from "../../context/SidebarContext";

export default function UserInfo() {
  const userInfo = useContext(InfoContext);
  // const { currency } = useContext(SidebarContext);

  return (
    <div className="dropdown user-pro-body text-center">
      <div className="user-pic">
        <img
          src="../../assets/images/users/2.jpg"
          alt="user-img"
          className="avatar-xl rounded-circle mb-1"
        />
      </div>
      <div className="user-info">
        <h5 className=" mb-1">
          {userInfo.fname} {userInfo.lname}{" "}
          <i className="ion-checkmark-circled  text-success fs-12"></i>
        </h5>

        <div className="app-sidebar__user-name text-sm" style={{ display: "inline-flex", alignItems: "center", gap: "5px" }}>
          <span
            className=""
            style={{ textTransform: "uppercase", cursor: "pointer", fontWeight: "bold" }}
          >
            {/* {currency} */}
            usd
          </span>
        </div>
      </div>
    </div>
  );
}
