import axios from "../../axios"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext";
import { NavLink } from "react-router-dom";

const BlogLink = () => {
  const { token } = useContext(AuthContext);
  const [count, setCount] = useState(null);

  const getUnReadBlogs = async () => {
    try {
      const res = await axios.get('/blog/unread', { headers: { Authorization: `Bearer ${token}` } })
      setCount(res.data.count);
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => { getUnReadBlogs() }, []);

  return (
    <li className="">
      <NavLink className={({ isActive }) => `side-menu__item ${isActive && "active"}`} to="/blog">
        <svg
          className="side-menu__icon"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M15.2 18.21c1.21.41 2.48.67 3.8.76v-1.5c-.88-.07-1.75-.22-2.6-.45l-1.2 1.19zM6.54 5h-1.5c.09 1.32.35 2.59.75 3.79l1.2-1.21c-.24-.83-.39-1.7-.45-2.58zM14 8h5V5h-5z"
            opacity=".3"
          />
          <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h12v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
        </svg>
        <span className="side-menu__label">Blog</span>
        {count > 0 && <span className="notify mr-2">{count > 9 ? "9+" : count}</span>}
        <i className="angle fa fa-angle-right"></i>
      </NavLink>
      <ul
        className="slide-menu "
        style={{ background: "none", boxShadow: "none" }}
      ></ul>
    </li>
  )
}

export default BlogLink
