import React, { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import PageNav from "../../components/PageNav";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import useScripts from "../../hooks/useScripts";
import BlogForm from "./BlogForm";
import { AuthContext } from "../../context/AuthContext";
import { useParams } from "react-router-dom";
import axios from "../../axios";

const scripts = [
  "../assets/plugins/select2/select2.full.min.js",
  "../assets/js/select2.js",
];

export default function GlobalChanges() {
  const [loading] = useScripts(scripts, true);
  useCloseSidebar();
  const { specialToken, setSpecialPopup } = useContext(AuthContext)
  const { slug } = useParams();
  const initState = { title: "", description: "", content: "", mainPicture: "" }
  const [post, setPost] = useState({ ...initState })

  const getPrevData = async () => {
    try {
      setPost(null)
      const res = await axios.get(`/blog/${slug}`);
      if (!res.data.post)
        return window.location = "/blog"
      setPost(res.data.post)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!slug) return;
    getPrevData();
  }, [slug])


  useEffect(() => {
    if (!specialToken) setSpecialPopup(true)
  }, [specialToken])

  if (loading || !post) return <Loader />;

  return (
    <Layout pageNav={<PageNav title="Vytvořit blog" subtext="Vytvořit blog" />}>
      <div className="row">
        <BlogForm slug={slug} {...post} edit={!!slug} />
      </div>
    </Layout>
  );
}
