import { useContext, useState } from "react"
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";

const useAddNft = (user, cb) => {
  const [status, setStatus] = useState("");
  const auth = useContext(AuthContext);

  const onSubmit = async (idx, col) => {
    setStatus("loading")
    try {
      const res = await axios.post(`/nft`, { idx, col, user }, {headers: {Authorization: `Bearer ${auth.token}`}});
      cb(res.data);
    } catch (err) {
      console.log(err);
      alert("something went wrong");
      setStatus("error");
    }

    setStatus("")
  }


  return [status, onSubmit]
}

export default useAddNft
