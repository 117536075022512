import React, { useState, useContext } from "react";
import axios from "../../axios";
import oAxios from "axios";
import { AuthContext } from "../../context/AuthContext";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useNavigate } from "react-router-dom";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './blog.css'


export default function GlobalProfit(props) {
  const initialState = {
    title: { value: props.title, error: "" },
    description: { value: props.description, error: "" },
    mainPicture: { value: props.mainPicture, error: "" },
  };

  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const navigate = useNavigate();

  const [uploadStatus, setUploadStatus] = useState(null);
  let prevContent = EditorState.createEmpty();
  if (props.edit && props.content) {
    const js = JSON.parse(props.content);
    if (js) {
      const raw = convertFromRaw(js);
      prevContent = EditorState.createWithContent(raw);
    }
  }
  const [content, setContent] = useState(prevContent);

  const auth = useContext(AuthContext);

  const headers = {
    headers: { Authorization: `Bearer ${auth.token}`, "special-token": `Bearer ${auth.specialToken}` },
  };


  const clearErrors = () => {
    const updatedState = { ...state };
    for (const item in updatedState) {
      updatedState[item].error = "";
    }
    setState(updatedState);
  };

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const customHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);
    clearErrors();

    try {
      let current_content = content.getCurrentContent();
      current_content = JSON.stringify(convertToRaw(current_content));

      const formData = { content: current_content };
      for (const item in state) {
        formData[item] = state[item].value;
      }

      const pheaders = {
        ...headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      };


      const method = props.edit ? "put" : "post";
      let url = '/blog'
      if (props.edit) url += `/${props.slug}`

      const res = await axios[method](url, formData, pheaders);
      const slug = res.data?.post?.slug;
      if (props.edit && slug) {
        setTimeout(() => {
          navigate(`/edit-blog/${slug}`, { replace: true });
        }, 1000);
      }

      setSuccess(true);
    } catch (err) {
      console.log(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Něco se pokazilo"]);
      }
    }

    setLoading(false);
  };

  const uploadPhoto = async e => {
    setUploadStatus(null);
    const file = e.target.files[0];

    if (!file) return;
    setUploadStatus("loading");
    const {
      data: {
        data: {
          config: { folder, signature, timestamp },
        },
      },
    } = await axios.get('/admin/signature?folder=blog', headers)

    const apiKey = process.env.REACT_APP_CLOUDINARY_API_KEY;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", apiKey);

    try {
      formData.append("signature", signature);
      formData.append("timestamp", timestamp);
      formData.append("folder", folder);

      const cloud = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
      const url = `https://api.cloudinary.com/v1_1/${cloud}/auto/upload`;
      const headers = { "Content-Type": "multipart/form-data" };
      const res = await oAxios.post(url, formData, headers);
      handleInput("mainPicture", res.data.secure_url)
      setUploadStatus("done");
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus("error");
    }
  }

  return (
    <div className="col-lg-12 col-xl-6 col-md-12 col-sm-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{props.edit ? "Upravit" : "Napsat"} příspěvek v blogu</h4>
        </div>
        <div className="card-body">
          <form onSubmit={customHandler}>
            <div className="">
              <div className="form-group">
                <label htmlFor="title" className="form-label">
                  Název
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Název blogu"
                  value={state.title.value}
                  onChange={(e) => handleInput("title", e.target.value)}
                />
                {<small className="text-danger block mt-1">
                  {state.title.error}
                </small>}
              </div>
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Krátký popis blogu
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Popis"
                  value={state.description.value}
                  onChange={(e) => handleInput("description", e.target.value)}
                />
                {<small className="text-danger block mt-1">{state.description.error}</small>}
              </div>

              <div className="form-group">
                <label className="form-label">Hlavní fotografie</label>
                <label htmlFor="mainPhoto" style={{
                  display: "inline-block", cursor: 'pointer', marginTop: "8px",
                  color: uploadStatus === "error" ? "red" : uploadStatus === "done" ? "green" : "white"
                }}>
                  {!uploadStatus && "Klepněte pro vložení"}
                  {uploadStatus === "loading" && "Vkládám . . ."}
                  {uploadStatus === "done" && "Vloženo"}
                  {uploadStatus === "error" && "Niečo sa pokazilo"}
                </label> <br />
                <input type="file" id="mainPhoto" accept="images/*" onChange={uploadPhoto} hidden={true} />
                <small className="text-success">{state.mainPicture?.value}</small>
                <small className="text-danger">{state.mainPicture?.error}</small>
              </div>

              <div className="form-group">
                <label className="form-label">Obsah</label>
                <Editor
                  editorState={content}
                  wrapperClassName="editor-wrapper"
                  editorClassName="post-editor"
                  onEditorStateChange={(editorState) => setContent(editorState)}
                  toolbar={{
                    image: {
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: (file) =>
                        new Promise((resolve, _) => {
                          const reader = new FileReader();

                          if (file) {
                            reader.readAsDataURL(file);
                          }
                          reader.addEventListener(
                            "load",
                            function() {
                              console.log(reader.result);
                              resolve({ data: { link: reader.result } });
                            },
                            false
                          );
                        }),
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "auto",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-0"
              disabled={loading}
            >
              {loading ? "Předává se" : "Předložit"}
            </button>
            {success && (<small className="text-success block mt-2">Úspěšně {props.edit ? "aktualizováno" : "vytvořil nový"} příspěvek v blogu</small>)}
            {errors &&
              errors.map((error, i) => (
                <small key={`error-${i}`} className="text-danger block mt-2">
                  {error}
                </small>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}
