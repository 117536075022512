import React, { useEffect, useState, useContext } from 'react'
import Layout from '../../components/Layout.jsx'
import Loader from '../../components/Loader.jsx'
import PageNav from '../../components/PageNav.jsx'
import axios from '../../axios.js';
import { AuthContext } from '../../context/AuthContext.js'
import useCloseSidebar from "../../hooks/useCloseSidebar";
import useScripts from '../../hooks/useScripts.jsx'
import Row from './Row.jsx';
import DelPopup from '../Dashboard/admin/Popup.jsx';
import Edit from './Edit.jsx';
import { SidebarContext } from '../../context/SidebarContext.js';

const scripts = [
  "../assets/plugins/datatable/js/jquery.dataTables.js",
  "../assets/plugins/datatable/js/dataTables.bootstrap4.js",
  "../assets/plugins/datatable/dataTables.responsive.min.js",
  "../assets/plugins/datatable/responsive.bootstrap4.min.js",
];


export default function History() {
  const [loadingScript] = useScripts(scripts, true);
  const auth = useContext(AuthContext);
  const { currency } = useContext(SidebarContext);
  const [histories, setHistories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDel, setShowDel] = useState("");
  const [showEdit, setShowEdit] = useState("");
  useCloseSidebar();

  const getHistories = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/user/recent-changes/${currency}`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setHistories(res.data?.recent);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
    setTimeout(() => initDatatable(), 500);
  }

  const initDatatable = () => {
    const table = window.$("#history").DataTable({
      lengthChange: false,
      language: {
        searchPlaceholder: "Hledat...",
        sSearch: "",
        lengthMenu: "_MENU_ "
      }
    });
    table.buttons().container().appendTo("#history_wrapper .col-md-6:eq(0)");
  };

  useEffect(() => {
    if (!loadingScript)
      getHistories();
  }, [loadingScript]);

  const handleUpdate = (id, data) => {
    const updatedHistory = histories
      .map(history => history._id == id ? { ...history, ...data } : history)
      .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
    setHistories(updatedHistory);
  }

  const handleRemove = (id) => {
    const updatedHistory = histories.filter(history => history._id !== id);
    setHistories(updatedHistory);
  }

  const renderItems = () => {
    if (!histories.length) return null;

    const items = histories.map((item) => {
      const { amount, dateCreated, _id, transType, user, profitInPercent } = item;
      const username = `${user.fname} ${user.lname}`
      return (
        <Row
          key={_id}
          id={_id}
          amount={amount}
          profitInPercent={profitInPercent}
          client={username}
          transType={transType}
          date={dateCreated}
          setShowDel={() => setShowDel(_id)}
          setShowEdit={() => setShowEdit(_id)}
        />
      );
    });
    return items.reverse();
  };

  if (loadingScript || loading) {
    return <Loader />
  }

  let selected = null;
  if (showEdit) {
    selected = histories.find(item => item._id === showEdit);
  } else if (showDel) {
    selected = histories.find(item => item._id === showDel);
  }

  return (
    <Layout pageNav={<PageNav />}>
      <div className="card">
        <div className="card-header">
          <div className="card-title" style={{ textTransform: "none" }}>
            Pohyby na účtu
          </div>
        </div>
        <div className="card-body" style={{ marginTop: "-55px" }}>
          <div className="">
            <div className="table-responsive">
              <table
                id="history"
                className="table table-bordered text-nowrap key-buttons"
              >
                <thead>
                  <tr>
                    <th className="border-bottom-0">Množství</th>
                    <th className="border-bottom-0">Klient</th>
                    <th className="border-bottom-0">Den okamžiku</th>
                    <th className="border-bottom-0">Typ transakce</th>
                    <th className="border-bottom-0">Volba</th>
                  </tr>
                </thead>
                <tbody>{renderItems()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <DelPopup
        show={!!showDel}
        close={() => setShowDel("")}
        title="Jsi si jistý?"
        commit={async () => {
          await axios.delete(`/admin/transaction/${showDel}`, {
            headers: {
              Authorization: `Bearer ${auth.token}`
            }
          });
          handleRemove(showDel);
          setShowDel("");
        }} />
      <Edit
        showEdit={!!showEdit}
        setShowEdit={setShowEdit}
        amount={selected?.amount}
        amountInPercent={selected?.profitInPercent}
        dateCreated={selected?.dateCreated}
        id={showEdit}
        update={handleUpdate}
      />
    </Layout>
  )
}
