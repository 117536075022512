import { useEffect, useState } from "react";
import styles from "./index.module.css";
import useUserNfts from "./useUserNfts";
import GridItem from "./GridItem";

const COLLECTION_LENGTH = 40;

const ImageGrid = ({ userId }) => {
  const [collection, setCollection] = useState(1);
  const { state, loading, getNftId, addNft, removeNft } = useUserNfts(userId);

  useEffect(() => {
    if (userId || loading) return;

    setCollection(state.collections?.[0]);
  }, [loading, userId])

  const render = () => {
    const html = [];
    for (let i = 1; i <= 25; i++) {
      html.push(<GridItem unavailable={state.unavailable?.[`${i}-${collection}`]} idx={i} userId={userId} addNft={addNft} removeNft={removeNft} collection={collection} nftId={getNftId(i, collection)} />);
    }
    return html;
  };

  const renderAdminOpts = () => {
    const html = [];
    for (let i = 1; i <= COLLECTION_LENGTH; i++) {
      html.push(
        <option value={i} key={`opt-${i}`}>
          Collection # {i}
        </option>,
      );
    }
    return html;
  };

  const renderOpts = () => {
    return state.collections.sort((a,b) => a - b).map(i => <option value={i} key={`opt-${i}`}>
      Collection # {i}
    </option>)
  };

  if (loading)
    return (
      <div className={styles.load}>
        <img src="../assets/images/svgs/loader.svg" alt="loader" />
      </div>
    );

  return (
    <div className={styles.container}>
      <select
        className="form-control select2"
        value={collection}
        onChange={(e) => setCollection(+e.target.value)}
      >
        {userId ? renderAdminOpts() : renderOpts()}
      </select>
      <section className={styles.grid}>{render()}</section>
    </div>
  );
};

export default ImageGrid;
