import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import BlogLink from "./BlogLink";

export default function Menu() {
  const auth = useContext(AuthContext);

  const adminLinks = () => {
    if (!auth.isAdmin) return;
    return (
      <>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/global-changes">
            <svg
              className="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            <span className="side-menu__label">Globální úpravy</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu "
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/history">
            <svg
              className="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
            <span className="side-menu__label">Všechny změny</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu"
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
        <li className="">
          <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/create-blog">
            <svg
              className="side-menu__icon"
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path
                d="M16.66 4.52l2.83 2.83-2.83 2.83-2.83-2.83 2.83-2.83M9 5v4H5V5h4m10 10v4h-4v-4h4M9 15v4H5v-4h4m7.66-13.31L11 7.34 16.66 13l5.66-5.66-5.66-5.65zM11 3H3v8h8V3zm10 10h-8v8h8v-8zm-10 0H3v8h8v-8z"
              /></svg
            >
            <span className="side-menu__label">Vytvořit blog</span>
            <i className="angle fa fa-angle-right"></i>
          </NavLink>
          <ul
            className="slide-menu"
            style={{ background: "none", boxShadow: "none" }}
          ></ul>
        </li>
      </>
    );
  };
  return (
    <>
      {/* <SwitchCurrency /> */}

      <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/dashboard">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
          </svg>
          <span className="side-menu__label">Dashboard</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>

        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>

      {!auth.isAdmin && <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/wallet">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <g fill="none" fill-rule="evenodd">
              <circle cx="16" cy="16" r="16" fill="#26A17B" />
              <path fill="#FFF"
                d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117" />
            </g>
          </svg>
          <span className="side-menu__label">NFT Wallet</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>

        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>
      }

      <li className="">
        <NavLink className={navData => `side-menu__item ${navData.isActive && "active"}`} to="/user-profile">
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
          </svg>
          <span className="side-menu__label">Můj profil</span>
          <i className="angle fa fa-angle-right"></i>
        </NavLink>
        <ul
          className="slide-menu "
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>


      {adminLinks()}


      <BlogLink />

      <li className="">
        <a
          className="side-menu__item"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            auth.logout();
          }}
        >
          <svg
            className="side-menu__icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <span className="side-menu__label">Odhlásit se</span>
          <i className="angle fa fa-angle-right"></i>
        </a>
        <ul
          className="slide-menu"
          style={{ background: "none", boxShadow: "none" }}
        ></ul>
      </li>
    </>
  );
}
