import React from "react";
import Chart from "react-apexcharts";

export default function Card(props) {
  const sparkOptions = {
    chart: {
      type: "area",
      height: 60,
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        blur: 3,
        opacity: 0.2,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    fill: {
      gradient: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: props.dataName,
        data: props.data,
      },
    ],
    yaxis: {
      min: 0,
    },
    colors: [props.color],
  };
  return (
    <div className={`col-xl-${props.larger ? "6" : "4"} col-lg-6 col-md-6 col-xm-12`}>
      <div className="card overflow-hidden dash1-card border-0">
        <div className="card-body">
          <p className=" mb-1 text-muted">
            {props.isAdmin ? props.adminTitle : props.userTitle}
          </p>
          <h1 className="mb-1 number-font" style={{ fontSize: "1.8rem" }}>
            {Number(+parseFloat(props.amount).toFixed(2)).toLocaleString()} {props.symbol}
          </h1>
        </div>
        <Chart
          type="area"
          series={sparkOptions.series}
          options={sparkOptions}
          height={sparkOptions.chart.height}
        />
      </div>
    </div>
  );
}
