const cardData = [
  {
    type: "capital",
    adminTitle: "Hodnota portfólia",
    userTitle: "Hodnota portfólia",
    dataName: "Capital",
    color: "#fa007a",
    symbol: "$"
  },
  {
    type: "profit",
    adminTitle: "Výnosy",
    userTitle: "Výnosy",
    dataName: "Profit",
    color: "#2ece89",
    symbol: "$"
  },
  {
    type: "deposit",
    adminTitle: "Počet NFT",
    userTitle: "Počet NFT",
    dataName: "deposit",
    color: "#0096b1",
    symbol: "NFT"
  }
];

const getCapital = (recent) => {
  const data = [0];
  for (const item of recent) {
    let amount = item.amount;

    const lastItem = data[data.length - 1];
    if (item.transType === "withdrawal")
      amount = lastItem - item.amount;
    else
      amount = lastItem + item.amount;
    data.push(amount);
  }
  return data;
}

const getProfit = (recent) => {
  const data = [0];
  for (const item of recent) {
    if (item.transType !== "profit" && item.transType !== "withdrawal") continue;

    let amount = item.amount;
    const lastItem = data[data.length - 1];
    if (item.transType === "withdrawal") {
      if (item.withdrawType === "from-profit") {
        amount = lastItem - amount;
      } else {
        continue;
      }
    } else {
      amount += lastItem;
    }
    data.push(amount);
  }
  return data;
}

const getDeposit = (recent) => {
  const data = [0];
  for (const item of recent) {
    if (item.transType !== "deposit" && item.transType !== "withdrawal") continue;

    let amount = item.amount;
    const lastItem = data[data.length - 1];
    if (item.transType === "withdrawal") {
      if (item.withdrawType === "from-deposit") {
        amount = lastItem - amount;
      } else {
        continue;
      }
    } else {
      amount += lastItem;
    }
    data.push(amount);
  }
  return data;
}
const getWithdrawal = (recent) => {
  const data = [0];
  for (const item of recent) {
    if (item.transType !== "withdrawal") continue;

    let amount = item.amount;
    const lastItem = data[data.length - 1];
    amount += lastItem;
    data.push(amount);
  }

  return data;
}

const getStats = (recent) => {
  const stats = { profit: 0, deposit: 0, withdrawal: 0 };
  for (const s in stats) {
    stats[s] = recent.reduce((accu, item) => accu + (item.transType === s ? item.amount : 0), 0);
  }
  stats.capital = stats.deposit + stats.profit - stats.withdrawal;

  // now reduce withdraw from profit
  for (const item of recent) {
    if (item.transType === "withdrawal") {
      if (item.withdrawType == "from-profit")
        stats.profit -= item.amount;
      else if (item.withdrawType == "from-deposit")
        stats.deposit -= item.amount;
    }
  }
  return stats;
}

export { cardData, getCapital, getProfit, getDeposit, getWithdrawal, getStats }
