import { useContext, useEffect, useState } from "react";
import "./QRPopup.css";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const DeleteUserPopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { setSpecialPopup, specialToken, token } = useContext(AuthContext)

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    try {
      const headers = {
        headers: { Authorization: `Bearer ${token}`, "special-token": `Bearer ${specialToken}` },
      };
      await axios.delete(`/admin/client/${props.userId}`, headers);
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      const msg = err?.response?.data?.messages || 'Něco se pokazilo';
      setError(msg)
    }
    setLoading(false)
  };

  useEffect(() => {
    setError("");
    if (!specialToken && props.open)
      setSpecialPopup(true);
  }, [specialToken, props.open])

  return (
    <>
      <div
        className={`${props.open && "open"} qr-modal`}
        onClick={props.close}
      ></div>
      <div
        className={`${props.open && "open"} qr-container`}
        style={{ height: "auto" }}
      >
        <h2 style={{ marginBottom: "3rem" }}>Jsi si jistý?</h2>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <button className="btn btn-primary" onClick={props.close}>
            Žádný
          </button>
          <button
            className="btn btn-danger"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Mazání..." : "Ano"}
          </button>{" "}
        </div>
        {error && <small className="text-danger block mt-3">{error}</small>}</div>
    </>
  );
};

export default DeleteUserPopup;
