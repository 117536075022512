import { useContext, useState } from "react"
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";

const useRemoveNft = (cb) => {
  const [status, setStatus] = useState("");
  const auth = useContext(AuthContext);

  const onSubmit = async (id) => {
    setStatus("loading")
    try {
      await axios.delete(`/nft/${id}`, {headers: {Authorization: `Bearer ${auth.token}`}});
      cb(id);
    } catch (err) {
      console.log(err);
      alert("something went wrong");
      setStatus("error");
    }

    setStatus("")
  }


  return [status, onSubmit]
}

export default useRemoveNft
