import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from 'react'

import "./assets/plugins/bootstrap/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/dark.css";
import "./assets/css/skin-modes.css";
import "./assets/css/animated.css";
import "./assets/css/icons.css";
import "./assets/colors/color1.css";
import "./index.css";

import { AuthState } from "./context/AuthContext";
import NonAuthRoutes from "./routes/nonAuth";
import AuthUserRoutes from "./routes/authUser";
import AdminRoutes from "./routes/admin";
import { InfoState } from "./context/InfoContext";
import { SidebarState } from "./context/SidebarContext";
// import Blog from "./pages/Blog";
// import BlogPost from "./pages/BlogPost";

function App() {
  useEffect(() => {
    const el = document.querySelector("body");
    const present = el.classList.contains("dark-mode");
    const theme = localStorage.getItem("theme");

    if (!present && (theme === "dark" || !theme)) {
      el.classList.add("dark-mode");
    } else if (theme === "light" && present) {
      el.classList.remove("dark-mode");
    }
  }, []);

  return (
    <AuthState>
      <InfoState>
        <SidebarState>
          <BrowserRouter>
            <NonAuthRoutes />
            <AuthUserRoutes />
            <AdminRoutes />
            {/* <Routes> */}
            {/*   <Route path="/blog" element={<Blog />} /> */}
            {/*   <Route path="/blog/:slug" element={<BlogPost />} /> */}
            {/* </Routes> */}
          </BrowserRouter>
        </SidebarState>
      </InfoState>
    </AuthState>
  );
}

export default App;
