import React, { useContext } from "react";
import HeaderLogo from "../MyHeader/HeaderLogo";
import Menu from "./Menu";
import NavIcons from "./NavIcons";
import UserInfo from "./UserInfo";
import styles from "./index.module.css";
import { SidebarContext } from "../../context/SidebarContext";

export default function Sidebar() {
  const sidebar = useContext(SidebarContext);

  const closeSidebar = () => {
    document.querySelector(".app-sidebar__toggle").click();
    sidebar.close();
  };
  return (
    <>
      <aside className={`app-sidebar ${styles.appSidebar}`}>
        <div className="app-sidebar__logo">
          <HeaderLogo />
        </div>
        <div className="app-sidebar__user">
          <UserInfo />
          <NavIcons />
        </div>
        <div className="main-sidemenu">
          <ul className="side-menu">
            <li className="side-item side-item-category mt-4">Hlavní menu</li>
            <Menu />
          </ul>
        </div>
      </aside>
      {sidebar.isOpen && (
        <div
          className={styles["sidebar-closer-mobile"]}
          onClick={closeSidebar}
        ></div>
      )}
    </>
  );
}
