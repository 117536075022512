import moment from "moment";
import React, { useEffect } from "react";

const Row = (props) => {
  const { transType } = props;

  let color;
  let bg;
  switch (transType) {
    case "deposit":
      color = "#816ed9";
      bg = "#c9c0f4";
      break;
    case "withdrawal":
      color = "#fdbf01";
      bg = "#fce7a7"
      break;
    default:
      color = "#2cce8a";
      bg = "#cdecdd";
  }
  let symbol = "+";
  let amt = props.profit;
  if (amt < 0) {
    symbol = "-";
    amt *= -1;
  }
  return (
    <tr>
      <td>
        <a style={{ color: "#2cce8a" }}>
          {symbol} {Number(amt).toLocaleString()}$
          {transType === "deposit" && ` (${Math.round(amt / 1000)} NFT)`}
        </a>
      </td>
      <td>
        <a style={{ color: "#0096b1" }}>
          {transType === "deposit" ? "---" : `${+parseFloat(props.percent).toFixed(2)} %`}
        </a>
      </td>
      <td>{moment(props.date).format("DD.MM.YYYY")}</td>
      <td>
        <a
          style={{
            border: `solid 1.5px ${color}`,
            borderRadius: "7px",
            background: bg,
            padding: "2px 8px 2px 8px",
            color: "#000000",
            boxShadow: "rgb(0 0 0 / 16%) 0px 1px 4px",
          }}
        >
          {transType}
        </a>
      </td>
      <td>{Number(props.capital).toLocaleString()} $</td>
    </tr>
  );
};

export default function ProfitHistory(props) {
  const initDatatable = () => {
    const table = window.$("#example").DataTable({
      lengthChange: false,
      order: [],
      buttons: ["excel", "pdf", "colvis"],
      language: {
        searchPlaceholder: "Hledat...",
        sSearch: "",
        lengthMenu: "_MENU_ ",
      }
    });
    table.buttons().container().appendTo("#example_wrapper .col-md-6:eq(0)");
  };

  useEffect(() => {
    initDatatable();
  }, []);

  const renderItems = () => {
    const recent = props.recent?.reverse();
    if (!recent.length) return null;
    const history = [];

    let capital = 0;
    for (let i = 0; i < recent.length; i++) {
      const item = { ...recent[i] };
      const { transType, amount } = item;
      const percent = capital === 0 ? 100 : amount / capital * 100;

      if (transType === "withdrawal" || transType === "company-cost") {
        capital -= amount;
      } else if (transType === "deposit"){
        capital += amount;
      }

      history.push(
        {
          capital,
          profit: amount,
          percent,
          _id: item._id,
          dateCreated: item.dateCreated,
          transType: item.transType
        }
      );
    }

    const items = history.map((item) => {
      const { profit, capital, percent, dateCreated, _id, transType } = { ...item };
      return (
        <Row
          key={_id}
          transType={transType}
          capital={parseFloat(capital).toFixed(2)}
          profit={parseFloat(profit).toFixed(2)}
          percent={parseFloat(percent).toFixed(3)}
          date={dateCreated}
        />
      );
    });

    return items.reverse();
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title" style={{ textTransform: "none" }}>Pohyby na účtu</div>
      </div>
      <div className="card-body">
        <div className="">
          <div className="table-responsive">
            <table
              id="example"
              className="table table-bordered text-nowrap key-buttons"
            >
              <thead>
                <tr>
                  <th className="border-bottom-0">POHYB</th>
                  <th className="border-bottom-0">Úrokový výnos</th>
                  <th className="border-bottom-0">Den pohybu</th>
                  <th className="border-bottom-0">Typ transakce</th>
                  <th className="border-bottom-0">VKLAD</th>
                </tr>
              </thead>
              <tbody>{renderItems()}</tbody>
            </table>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
