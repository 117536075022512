import { useState } from "react";
import "./QRPopup.css";
import axios from "../../axios";

const DownloadPDF = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setError('');
    try {
      const res = await axios.post(`/user/download-contract?id=${props.userId}`, { password }, props.headers);
      const { url } = res.data;
      if (url) {
        const a = document.createElement('a')
        a.setAttribute("href", url);
        a.setAttribute("download", "contract.pdf");
        a.setAttribute("target", "_blank");
        a.click();
      }
      setPassword("")
    } catch (err) {
      console.log(err);
      const msg = err?.response?.data?.messages || 'Něco se pokazilo';
      setError(msg)
    }
    setLoading(false)
  };

  return (
    <>
      <div
        className={`${props.open && "open"} qr-modal`}
        onClick={props.close}
      ></div>
      <div
        className={`${props.open && "open"} qr-container`}
        style={{ height: "auto" }}
      >
        <h2 style={{ marginBottom: "3rem" }}>Stáhnout PDF</h2>

        <form onSubmit={handleSubmit}>
          <label htmlFor="docUserPass" className="form-label">Heslo</label>
          <input
            type="password"
            className="form-control"
            style={{ background: 'white', borderColor: "#333", color: "#333" }}
            id="docUserPass"
            placeholder="Zadejte heslo"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
            <button type="button" className="btn btn-secondary" onClick={props.close}>
              Zrušit
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? "Odesílání..." : "Předložit"}
            </button>{" "}
          </div>
        </form>

        {error && <small className="text-danger block mt-3">{error}</small>}</div>
    </>
  );
};

export default DownloadPDF;
