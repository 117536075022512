import React, { useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import useScripts from "../../hooks/useScripts";
import axios from "../../axios";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const initialState = {
  password: { value: "", error: "" },
  cpassword: {
    value: "", error: ""
  }
};
export default function ResetPassword() {
  useScripts([], true);
  const [p] = useSearchParams();
  const token = p.get('token');

  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.password.value !== state.cpassword.value) return setState({ ...state, cpassword: { value: state.cpassword.value, error: "Hesla se neshodují" } })

    setLoading(true);
    setErrors([]);


    const updatedState = { ...state };
    updatedState.password.error = "";
    updatedState.cpassword.error = "";
    setState(updatedState);

    try {
      const res = await axios.put(`/user/reset-password`, {
        password: state.password.value,
      }, { headers: { Authorization: `Bearer ${token}` } });
      auth.login(res.data.token);
    } catch (err) {
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Něco se pokazilo"]);
      }
    }

    setLoading(false);
  };

  const renderErrors = () =>
    errors.map((error) => (
      <small key={error} className="text-danger block mt-1 text-center">
        {error}
      </small>
    ));



  return (
    <div className="page login-container">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div className="card">
                    <div className="card-body">
                      <div className="text-center title-style mb-6">
                        <h1 className="mb-2">Změna hesla</h1>
                        <hr />
                        <p className="text-muted">Můžete si změnit své heslo</p>
                      </div>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="fe fe-lock"></i>
                          </div>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Nové heslo"
                          value={state.password.value}
                          onChange={(e) =>
                            handleInput("password", e.target.value)
                          }
                        />
                      </div>

                      <small
                        className="text-danger block mb-4 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        {state.password.error}
                      </small>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <i className="fe fe-lock"></i>
                          </div>
                        </div>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Potvrdit heslo"
                          value={state.cpassword.value}
                          onChange={(e) =>
                            handleInput("cpassword", e.target.value)
                          }
                        />
                      </div>

                      <small
                        className="text-danger block mb-4 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        {state.cpassword.error}
                      </small>
                      <div className="row">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn  btn-primary btn-block px-4"
                            onClick={handleSubmit}
                            disabled={loading}
                          >
                            Resetovať
                          </button>
                        </div>

                        <div className="col-12">{renderErrors()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
