import React, { useState } from "react";
import { Link } from "react-router-dom";
import useScripts from "../../hooks/useScripts";
import axios from "../../axios";

const initialState = { email: { value: "", error: '' } }

export default function ForgotPassword() {
  useScripts([], true);

  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    setErrors([]);

    const updatedState = { ...state };
    updatedState.email.error = "";
    setState(updatedState);

    try {
      await axios.post(`/user/forgot-password`, {
        email: state.email.value,
      });
      setSuccess(true);
    } catch (err) {
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
      } else {
        setErrors(["Něco se pokazilo"]);
      }
    }

    setLoading(false);
  };

  const renderErrors = () =>
    errors.map((error) => (
      <small key={error} className="text-danger block mt-1 text-center">
        {error}
      </small>
    ));

  return (
    <div className="page login-container">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <div
                    className="card"
                    style={{
                      boxShadow:
                        "rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0) 0px -3px 0px inset",
                      backgroundColor: "#062d51",
                      borderRadius: "25px",
                      border: "1px solid rgb(0 0 0)",
                    }}
                  >
                    <div className="card-body">
                      <p
                        style={{
                          textAlign: "center",
                          color: "#ffffff",
                          position: "relative",
                          bottom: "0px",
                          fontWeight: "bold",
                          fontSize: "18px",
                          pointerEvents: "none",
                        }}
                      >
                        Zapomenuté heslo
                      </p>
                      <div className="input-group mb-4">
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{
                              backgroundColor: "#062d51",
                              border: "none"
                            }}
                          >
                            <i
                              className="fe fe-mail"
                              style={{ color: "#fff" }}
                            ></i>
                          </div>
                        </div>
                        <input
                          className="form-control"
                          placeholder="Uživatelský email"
                          type="text"
                          value={state.email.value}
                          onChange={(e) =>
                            handleInput("email", e.target.value)
                          }
                          style={{
                            backgroundColor: "#072d51",
                            border: "1px solid rgb(21 23 26)",
                            borderRadius: "10px",
                            boxShadow:
                              "inset rgb(0 0 0 / 30%) 2px 2px 2.6px",
                          }}
                        />
                      </div>

                      <small
                        className="text-danger block mb-4 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        {state.email.error}
                      </small>
                      <div className="row">
                        <div className="col-12">
                          <button
                            onClick={handleSubmit}
                            type="button"
                            className="btn  btn-primary btn-block px-4"
                            disabled={loading}
                            style={{
                              borderRadius: "10px",
                              backgroundColor: "#0a4b70",
                              borderColor: "#000",
                              boxShadow: "0 0 10px -5px rgb(0 0 0)",
                            }}
                          >
                            <i className="fe fe-send"></i> Odeslat
                          </button>
                        </div>

                        <div className="col-12">{renderErrors()}</div>
                        {success && <p className="text-success text-center mt-2 col-12 mb-0">Zkontrolujte prosím svůj e-mail a resetujte heslo</p>}
                      </div>
                      <div className="text-center pt-2">
                        <div
                          className="font-weight-normal fs-16"
                          style={{ color: "#fff" }}
                        >
                          {/*Už máte účet?{" "}*/}
                          <Link
                            to="/"
                            className="btn-link font-weight-normal"
                            style={{
                              color: "#fff",
                              textDecoration: "none",
                              fontSize: "13px",
                            }}
                          >
                            Přihlaste se zde
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
