import axios from "../../axios";
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/AuthContext";

const useUserNfts = (userId) => {
  const [state, setState] = useState({ collections: [], nfts: [] });
  const [loading, setLoading] = useState(true);
  const auth = useContext(AuthContext);

  const getNfts = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/nft?userId=${userId}`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setState(res.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  const addNft = (nft) => {
    const uState = {...state};
    if (!uState.collections.includes(nft.col)) {
      uState.collections.push(nft.col);
    }

    uState.nfts.push({...nft});

    setState(uState);
  }

  const removeNft = (nftId) => {
    const uState = {...state};
    const nfts = uState.nfts.filter(i => i._id !== nftId);
    uState.nfts = nfts;

    setState(uState);
  }

  const getNftId = (idx, col) => {
    for (const nft of state.nfts) {
      if (nft.idx === idx && nft.col === col) return nft._id;
    }
  }

  useEffect(() => {
    getNfts();
  }, [userId]);

  return {state, loading, getNftId, addNft, removeNft};
}

export default useUserNfts
