import React, { useRef } from "react";
import useForm from "../../hooks/useForm";

export default function UserWithdrawal(props) {
  const initialState = {
    amount: { value: 0, error: "" },
    checked: { value: false, error: "" },
    date: { value: "", error: "" },
  };

  const { state, setState, loading, success, errors, handleInput, handleSubmit } =
    useForm({ ...initialState });
  const checkboxRef = useRef();

  const type = props.from === "deposit" ? "vkladu" : "zisku";
  let title = `Výběr ${type} klienta (Z celkového kapitálu)`;

  return (
    <div className="col-lg-12 col-xl-6 col-md-12 col-sm-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title" style={{ textTransform: "none", }}>{title}</h4>
        </div>
        <div className="card-body">
          <form
            onSubmit={async (e) => {
              const result = await handleSubmit(e, `/admin/withdraw`, "post", {
                userId: props.userId,
                type: props.from,
                account: props.account
              });
              if (result !== false) {
                setState({ ...initialState });
                checkboxRef.current.checked = false;
              }
            }
            }
          >
            <div className="">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Výber v $
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Zadejte výběr v $"
                  value={state.amount.value}
                  onChange={(e) => handleInput("amount", e.target.value)}
                />
                <small className="text-danger block mt-1">
                  {state.amount.error}
                </small>
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="form-label">Zadejte datum připsání</label>

                <div className="wd-200 mg-b-30">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18">
                          <path d="M0 0h24v24H0V0z" fill="none"></path>
                          <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H4V5h16zM4 21V10h16v11H4z"></path>
                          <path d="M4 5.01h16V8H4z" opacity=".3"></path>
                        </svg>
                      </div>
                    </div>
                    <input
                      className="form-control fc-datepicker"
                      placeholder="15.02.2000"
                      type="text"
                      value={state.date.value}
                      onChange={e => handleInput("date", e.target.value)}
                    />
                  </div>
                  <small className="text-danger block mt-1">
                    {state.date.error}
                  </small>
                </div>
              </div>
              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="example-checkbox2"
                  value={state.checked.value}
                  onChange={(e) => handleInput("checked", e.target.checked)}
                  ref={checkboxRef}
                />
                <span className="custom-control-label">
                  Potvrzuji zapsání výběru k účtu klienta.
                </span>
              </label>
              <small className="text-danger block mt-0">
                {state.checked.error}
              </small>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-0"
              disabled={loading}
            >
              {loading ? "Předávání" : "Zapsat výběr"}
            </button>
            {success && (
              <small className="text-success block mt-2">
                Kredit byl úspěšně stažen
              </small>
            )}
            {errors &&
              errors.map((error, i) => (
                <small key={`error-${i}`} className="text-danger block mt-2">
                  {error}
                </small>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}
