import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import axios from "../../axios";

import styles from "./SearchBar.module.css";

export default function SearchBar() {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const auth = useContext(AuthContext);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!search || !auth.isAdmin) return;

    try {
      const res = await axios.get(`/admin/search-client?search=${search}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      setUsers(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const renderUsers = () => {
    if (!users.length) return [];

    const userLists = users.map((user) => (
      <Link to={`/edit-user/${user._id}`}>
        <li key={user._id}>
          {user.fname} {user.lname}
        </li>
      </Link>
    ));

    return <ul className={styles.searchCon}>{userLists}</ul>;
  };

  useEffect(() => {
    const clearSearch = () => {
      setUsers([]);
    };
    document.body.addEventListener("click", clearSearch);
    return () => {
      document.body.removeEventListener("click", clearSearch);
    };
  }, []);

  return (
    <div className="mt-1">
      <form className="form-inline" onSubmit={submitHandler}>
        <div className="search-element">
          <input
            type="search"
            className="form-control header-search"
            placeholder="Hledat..."
            aria-label="Search"
            tabIndex="1"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className="btn btn-primary-color" type="submit">
            <svg
              className="header-icon search-icon"
              x="1008"
              y="1248"
              viewBox="0 0 24 24"
              height="100%"
              width="100%"
              preserveAspectRatio="xMidYMid meet"
              focusable="false"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg>
          </button>
          {renderUsers()}
        </div>
      </form>
    </div>
  );
}
