import useAddNft from "./useAddNft";
import useRemoveNft from "./useRemoveNft";
import styles from "./index.module.css";

const GridItem = ({ idx, collection, unavailable, nftId, userId, addNft, removeNft }) => {
  const [aStatus, addSubmit] = useAddNft(userId, addNft);
  const [dStatus, removeSubmit] = useRemoveNft(removeNft);

  const renderBtn = () => {
    if (!userId) return null;

    if (unavailable) return <button className={[styles.btn, "btn btn-dark"].join(" ")}
    >
      IN USE
    </button>

    return nftId ? (
      <button className={[styles.btn, "btn btn-danger"].join(" ")}
        onClick={removeSubmit.bind(null, nftId)}
        disabled={dStatus === "loading"}
      >
        {dStatus === "loading" ? "REMOVING" : "REMOVE"}
      </button>
    ) : (
      <button
        onClick={addSubmit.bind(null, idx, collection)}
        disabled={aStatus === "loading"}
        className={[styles.btn, "btn btn-primary"].join(" ")}
      >
        {aStatus === "loading" ? "ADDING" : "ADD"}
      </button>
    )
  }

  const renderInfo = () => {
    if (userId) return;
    if (nftId) return <span style={{ color: "rgba(213, 218, 226, 0.5)", alignSelf: "end", fontSize: "0.7rem", marginBottom: "0.25rem" }}>M&amp;P #{collection} ({idx}/25)</span>;

    return <span className={styles.notAvailable}>?</span>;
  }

  return <div
    style={{
      backgroundImage:
        nftId || userId ? `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.0)), url("/assets/nfts/${idx}.png")` : "",
    }}
    key={`question-${idx}`}
  >
    {renderBtn()}
    {renderInfo()}
  </div>
}

export default GridItem 
