import React from "react";
import { Link } from "react-router-dom";

export default function HeaderLogo() {
  return (
    <Link className="header-brand" to={"/dashboard"}>
      <img
        src="../assets/images/brand/logo.png"
        className="header-brand-img desktop-lgo"
        alt="Mark and Partners logo"
      />
      <img
        src="../assets/images/brand/logo1.png"
        className="header-brand-img dark-logo"
        alt="Mark and Partners logo"
      />
      <img
        src="../assets/images/brand/favicon.png"
        className="header-brand-img mobile-logo"
        alt="Mark and Partners logo"
      />
      <img
        src="../assets/images/brand/favicon1.png"
        className="header-brand-img darkmobile-logo"
        alt="Mark and Partners logo"
      />
    </Link>
  );
}
