import React from "react";
import Layout from "../../components/Layout";
import PageNav from "../../components/PageNav";
import ImageGrid from "../../components/ImageGrid";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import Form from './Form';

export default function Wallet() {
  useCloseSidebar();

  return (
    <Layout pageNav={<PageNav title="My NFT wallet" subtext="Wallet" />}>
      <Form />
      <ImageGrid userId="" />
    </Layout>
  );
}
