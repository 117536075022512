import { useContext, useState } from "react";
import "./QRPopup.css";
import { AuthContext } from "../../context/AuthContext";
import axios from '../../axios.js';

const initState = { verfication_code: { value: "", error: "" } }

const QRPopup = (props) => {
  const [state, setState] = useState({ ...initState });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [url, setUrl] = useState("");


  const auth = useContext(AuthContext);
  const headers = { headers: { Authorization: `Bearer ${auth.token}`, "special-token": `Bearer ${auth.specialToken}` } }

  const close = () => {
    setErrors([]);
    setLoading(false);
    setState({ ...initState });
    setSuccess(false);
    setUrl("");
    props.close();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setErrors([]);

    try {
      const userData = { verification_code: state.verfication_code.value };
      const res = await axios.put(`/user/${props.status ? "remove" : "add"}-two-factor`, userData, headers);
      setState({ ...initState });
      if (props.status) {
        setSuccess(true);
        setTimeout(close, 1000);
      } else setUrl(res.data.qr)
      props.toggleStatus();
    } catch (err) {
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setErrors([msgs]);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          if (updatedState[fieldError.param])
            updatedState[fieldError.param].error = fieldError.msg;
          else setErrors([fieldError.msg])
        });
        setState(updatedState);
      } else {
        setErrors(["Něco se pokazilo"]);
      }
    }

    setLoading(false);
  }

  if (url) {
    return <><div className={`${props.open && "open"} qr-modal`} onClick={close}></div>
      <div className={`${props.open && "open"} qr-container`}>
        <h2 className="mb-0 mt-1">Naskenujte prosím</h2>
        <img src={url} alt="QR" />
      </div></>
  }

  return (
    <>
      <div className={`${props.open && "open"} qr-modal`} onClick={close}></div>
      <div className={`${props.open && "open"} qr-container`}>
        <h2>Ověřte účet</h2>
        <p style={{ color: "#777" }}>Ověřte se prosím zadáním 6místného kódu, který vám zašleme na váš e-mail</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  style={{ color: "black", background: "#eee" }}
                  placeholder="Code"
                  maxLength={6}
                  value={state.verfication_code.value}
                  onChange={e => setState({ verfication_code: { value: e.target.value, error: "" } })}
                />
                <small className="text-danger block mt-1">
                  {state.verfication_code.error}
                </small>
              </div>
            </div>
          </div>
          <button className="btn btn-dark" onClick={close} type="button">Cancel</button>
          <button disabled={loading} className="btn btn-primary float-right" type="submit">{loading ? "Odesílání" : "Předložit"}</button>
        </form>
        {success && <p className="text-success mb-0 mt-2">Google auth bylo úspěšně odstraněno</p>}
        {errors &&
          errors.map((error, i) => (
            <small key={`error-${i}`} className="text-danger block mt-2">
              {error}
            </small>
          ))}
      </div>
    </>
  )
}

export default QRPopup;
