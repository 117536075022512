import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { InfoContext } from "../../context/InfoContext";
export default function DropDown() {
  const auth = useContext(AuthContext);
  const userInfo = useContext(InfoContext);
  return (
    <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow animated">
      <div className="text-center">
        <a
          href="#"
          className="dropdown-item text-center user pb-0 font-weight-bold"
        >
          {userInfo.fname} {userInfo.lname}
        </a>
        <span className="text-center user-semi-title upper" style={{textTransform: "uppercase"}}>
          {userInfo.currency}
        </span>
        <div className="dropdown-divider"></div>
      </div>
      <Link className="dropdown-item d-flex" to="/dashboard">
        <svg
          className="header-icon mr-3"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zM7 10h2v7H7zm4-3h2v10h-2zm4 6h2v4h-2z" />
        </svg>
        <div className="">Dashboard</div>
      </Link>
      <Link className="dropdown-item d-flex" to="/support">
        <svg
          className="header-icon mr-3"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M4 4h16v12H5.17L4 17.17V4m0-2c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2H4zm2 10h12v2H6v-2zm0-3h12v2H6V9zm0-3h12v2H6V6z" />
        </svg>
        <div className="">Podpora</div>
      </Link>
      <a
        className="dropdown-item d-flex"
        onClick={(e) => {
          e.preventDefault();
          auth.logout();
        }}
      >
        <svg
          className="header-icon mr-3"
          xmlns="http://www.w3.org/2000/svg"
          enableBackground="new 0 0 24 24"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <g>
            <rect fill="none" height="24" width="24" />
          </g>
          <g>
            <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z" />
          </g>
        </svg>
        <div className="">Odhlásit se</div>
      </a>
    </div>
  );
}
