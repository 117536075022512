import React, { useContext, useEffect } from "react";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import PageNav from "../../components/PageNav";
import useCloseSidebar from "../../hooks/useCloseSidebar";
import useScripts from "../../hooks/useScripts";
import GlobalProfit from "./GlobalProfit";
import { AuthContext } from "../../context/AuthContext";

const scripts = [
  "../assets/plugins/select2/select2.full.min.js",
  "../assets/js/select2.js",
];

export default function GlobalChanges() {
  const [loading] = useScripts(scripts, true);
  useCloseSidebar();
  const { specialToken, setSpecialPopup } = useContext(AuthContext)

  useEffect(() => {
    if (!specialToken) setSpecialPopup(true)
  }, [specialToken])

  if (loading) return <Loader />;

  return (
    <Layout pageNav={<PageNav title="Globální úpravy" subtext="Globální úpravy" />}>
      <div className="row">
        <GlobalProfit
          url="/admin/global-profit"
          title="Globální zisk"
          agreeText="Potvrzuji globální připsání výnosů."
        />
      </div>
    </Layout>
  );
}
