import React, { useState, useRef, useContext } from "react";
import useForm from "../../hooks/useForm";
import { SidebarContext } from "../../context/SidebarContext";

export default function GlobalProfit(props) {
  const { currency } = useContext(SidebarContext);

  const initialState = {
    type: { value: "FIXED", error: "" },
    amount: { value: 0, error: "" },
    date: { value: "", error: "" },
    checked: { value: false, error: "" },
    currency: { value: currency, error: "" }
  };

  const {
    state,
    setState,
    loading,
    errors,
    success,
    handleInput,
    handleSubmit,
  } = useForm(initialState);
  const [amount, setAmount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [date, setDate] = useState("");
  const checkboxRef = useRef();

  const customHandler = async (e) => {
    e.preventDefault();
    const updatedState = { ...state };
    updatedState.date.value = date;

    if (amount !== 0) {
      updatedState.amount.value = amount;
      updatedState.type.value = "FIXED";
    } else if (percent !== 0) {
      updatedState.amount.value = percent;
      updatedState.type.value = "PERCENT";
    } else {
      updatedState.amount.error = "Zadejte částku";
      return setState(updatedState);
    }
    const moreData = {};
    if (props.userId) moreData.userId = props.userId;

    setState(updatedState);
    try {
      const submit = await handleSubmit(e, props.url, "post", moreData);
      if (submit !== false) {
        setAmount(0);
        setPercent(0);
        setDate("");
        checkboxRef.current.checked = false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col-lg-12 col-xl-6 col-md-12 col-sm-12">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{props.title}</h4>
        </div>
        <div className="card-body">
          <form onSubmit={customHandler}>
            <div className="">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Zisk v $
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputEmail1"
                  placeholder="Zadajte zisk v $"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                {state.type.value === "FIXED" && (
                  <small className="text-danger block mt-1">
                    {state.amount.error}
                  </small>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Zisk v %
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Zadajte zisk v %"
                  value={percent}
                  onChange={(e) => setPercent(e.target.value)}
                />
                {state.type.value === "PERCENT" && (
                  <small className="text-danger block mt-1">
                    {state.amount.error}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword1" className="form-label">Zadejte datum připsání</label>

                <div className="wd-200 mg-b-30">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18">
                          <path d="M0 0h24v24H0V0z" fill="none"></path>
                          <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H4V5h16zM4 21V10h16v11H4z"></path>
                          <path d="M4 5.01h16V8H4z" opacity=".3"></path>
                        </svg>
                      </div>
                    </div>
                    <input
                      className="form-control fc-datepicker"
                      placeholder="15.02.2000"
                      type="text"
                      value={date}
                      onChange={e => setDate(e.target.value)}
                    />
                  </div>
                  <small className="text-danger block mt-1">
                    {state.date.error}
                  </small>
                </div>
              </div>

              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="example-checkbox2"
                  ref={checkboxRef}
                  value={state.checked.value}
                  onChange={(e) => handleInput("checked", e.target.checked)}
                />
                <span className="custom-control-label">{props.agreeText}</span>
              </label>
              <small className="text-danger block mt-1">
                {state.checked.error}
              </small>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-0"
              disabled={loading}
            >
              {loading ? "Předává se" : "Připsat zisk"}
            </button>
            {success && (
              <small className="text-success block mt-2">
                Kredit byl úspěšně přidán
              </small>
            )}
            {errors &&
              errors.map((error, i) => (
                <small key={`error-${i}`} className="text-danger block mt-2">
                  {error}
                </small>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}
