import React, { useState } from "react";
import styles from "./Popup.module.css";

export default function Popup(props) {
  const show = props.show ? styles.active : "";
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await props.commit();
    setLoading(false);
  }
  return (
    <>
      <div className={`${styles.modal} ${show}`} onClick={props.close}></div>
      <div className={`${styles.popup} ${show}`}>
        <h2 className="mt-2 p-4 text-center">{props.title}</h2>
        {props.children}
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={props.close}
          >
            Vrátit se
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? "Odstraňuje se" : "Odstranit"}
          </button>
        </div>
      </div>
    </>
  );
}
