import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import UserProfile from "../pages/UserProfile";
import Support from "../pages/Support";
import Partners from "../pages/Partners";
import Wallet from "../pages/Wallet";
import { AuthContext } from "../context/AuthContext";
import EditUser from "../pages/EditUser";
import BlogPost from "../pages/BlogPost";
import Blog from "../pages/Blog";

export default function AuthUserRoutes() {
  const auth = useContext(AuthContext);

  const renderPage = (page) => {
    if (!auth.token) return <Navigate to="/" />;
    return page;
  };

  return (
    <Routes>
      <Route path="/dashboard" element={renderPage(<Dashboard />)} />
      <Route path="/user-profile" element={renderPage(<UserProfile />)} />
      <Route path="/support" element={renderPage(<Support />)} />
      <Route path="/partners" element={renderPage(<Partners />)} />
      <Route path="/wallet" element={renderPage(<Wallet />)} />
      <Route path="/edit-user/:id" element={renderPage(<EditUser />)} />
      <Route path="/blog" element={renderPage(<Blog />)} />
      <Route path="/blog/:slug" element={renderPage(<BlogPost />)} />
    </Routes>
  );
}
