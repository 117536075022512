import React, { useState, useEffect, useContext } from 'react'
import styles from '../Dashboard/admin/Popup.module.css';
import moment from "moment";
import axios from '../../axios.js'
import { AuthContext } from '../../context/AuthContext';

export default function Edit({ showEdit, setShowEdit, amount, amountInPercent, dateCreated, id, update }) {
  const perValue = (amountInPercent && Number(amountInPercent.split(" ")[0]));
  const initialState = {
    date: { value: moment(dateCreated).format("DD-MM-YYYY"), error: "" },
    amount: { value: perValue || amount, error: "" },
  }
  const [state, setState] = useState({ ...initialState });
  const [status, setStatus] = useState("");
  const symbol = amountInPercent ? "%" : "€";
  const { token, specialToken, setSpecialPopup } = useContext(AuthContext)

  useEffect(() => {
    if (!specialToken && showEdit)
      setSpecialPopup(true)
  }, [specialToken, showEdit]);

  useEffect(() => {
    const prevVal = amountInPercent && Number(amountInPercent.split(" ")[0]);
    const updatedState = { ...state };
    updatedState.date.value = moment(dateCreated).format("DD-MM-YYYY");
    updatedState.amount.value = prevVal || amount;
    setState(updatedState);
  }, [amount, amountInPercent, dateCreated]);

  const clearErrors = () => {
    const updatedState = { ...state };
    updatedState.date.error = "";
    updatedState.amount.error = "";
    setState(updatedState);
  }

  const handleInput = (field, value) => {
    const updatedState = { ...state };
    updatedState[field].value = value;
    setState(updatedState)
  }

  const handleClose = () => {
    setStatus("");
    setState({ ...initialState });
    setShowEdit("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("loading");
    clearErrors();
    try {
      const formData = {
        type: symbol === "%" ? "PERCENT" : "FIXED",
        date: state.date.value,
        amount: state.amount.value
      };
      const headers = { headers: { Authorization: `Bearer ${token}`, "special-token": `Bearer ${specialToken}` } };
      const url = `/admin/transaction/${id}`;
      const res = await axios.put(url, formData, headers);

      setStatus("success");
      const arr = state.date.value.split("-");
      const fmtDate = `${arr[2]}-${arr[1]}-${arr[0]}`;
      const newItem = { dateCreated: fmtDate, amount: res.data.actualAmount };
      update(id, newItem);
    } catch (err) {
      console.error(err);
      const msgs = err.response?.data?.messages;
      const fieldErrors = err.response?.data?.errors;
      if (msgs) {
        setStatus(`Error: ${msgs}`);
      } else if (fieldErrors) {
        const updatedState = { ...state };
        fieldErrors.forEach((fieldError) => {
          updatedState[fieldError.param].error = fieldError.msg;
        });
        setState(updatedState);
        setStatus("");
      } else {
        setStatus("Error: Něco se pokazilo");
      }
    }
  }

  const show = showEdit ? styles.active : "";

  return (
    <>
      <div className={`${styles.modal} ${show}`} onClick={handleClose}></div>
      <div className={`${styles.popup} ${show}`}>
        <h2 className="mt-2 p-4 text-center">Upravit transakci</h2>
        <div className="px-5">
          <div className="form-group">
            <label htmlFor="exampleInputPassword1" className="form-label">
              Částka v {symbol}
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleInputPassword1"
              placeholder={`Zadejte zisk v ${symbol}`}
              value={state.amount.value}
              onChange={(e) => handleInput("amount", e.target.value)}
            />
            <small className="text-danger block mt-1">
              {state.amount.error}
            </small>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1" className="form-label">Zadejte datum připsání</label>

            <div className="wd-200 mg-b-30">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18">
                      <path d="M0 0h24v24H0V0z" fill="none"></path>
                      <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 2v3H4V5h16zM4 21V10h16v11H4z"></path>
                      <path d="M4 5.01h16V8H4z" opacity=".3"></path>
                    </svg>
                  </div>
                </div>
                <input
                  className="form-control fc-datepicker"
                  placeholder="15.02.2000"
                  type="text"
                  value={state.date.value}
                  onChange={e => handleInput("date", e.target.value)}
                />
              </div>
              <small className="text-danger block mt-1">
                {state.date.error}
              </small>
            </div>
          </div>
          {status === "success" && <small className="text-success block mt-1">
            Záznam byl úspěšně aktualizován
          </small>}

          {status.includes("Error:") && <small className="text-danger block mt-1">{status}</small>}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleClose}
          >
            Vrátit se
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSubmit}
            disabled={status === "loading"}
          >
            {status === "loading" ? "Aktualizuje se..." : "Aktualizovat"}
          </button>
        </div>
      </div>
    </>
  )
}

