import * as echarts from "echarts";
import { getMonthlyData, transMonths, transMonthsYears } from "./getMonths";
import { getYearlyData, years } from "./getYearly";

const getXAxis = (xAxis) => {
  switch (xAxis) {
    case "yearly":
      return years;
    case "monthly":
      return transMonths.map(month => `${month[0].toUpperCase()}${month.slice(1)}`);
    default:
      throw new Error("unknown type");
  }
};

const getOptions = (recent, interval, isAdmin) => {
  const getData = (type) => {
    switch (interval) {
      case "yearly":
        return getYearlyData([...recent], type);
      case "monthly":
        return getMonthlyData([...recent], type);
      default:
        throw new Error("unknown type");
    }
  };

  const chartdata = [
    {
      name: isAdmin ? "Hodnota portfólia" : "Hodnota portfólia",
      symbolSize: 20,
      barWidth: 20,
      type: "bar",
      data: getData("capital"), // in fact only deposit
      itemStyle: {
        normal: {
          barBorderRadius: [50, 50, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#0096b1" },
            { offset: 1, color: "#20c2fa" },
          ]),
        },
      },
    },
    {
      name: isAdmin ? "Výnosy" : "Výnosy",
      type: "line",
      smooth: true,
      data: getData("profit"),
      itemStyle: {
        normal: {
          barBorderRadius: [50, 50, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#fd6f82" },
            { offset: 1, color: "#fb1c52" },
          ]),
        },
      },
    },
  ];

  let prevYear = new Date().getFullYear();

  const option = {
    grid: {
      top: "6",
      right: "0",
      bottom: "17",
      left: "35",
    },
    xAxis: {
      data: getXAxis(interval),
      axisLine: {
        lineStyle: {
          color: "rgba(67, 87, 133, .09)",
        },
      },
      axisLabel: {
        fontSize: 10,
        color: "#8e9cad",
      },
    },
    tooltip: {
      show: true,
      showContent: true,
      alwaysShowContent: true,
      triggerOn: "mousemove",
      trigger: "axis",
      axisPointer: {
        label: {
          show: false,
        },
      },
      formatter: (params) => {
        const month = params[0].axisValue;
        const s1n = params[0].seriesName;
        const s1v = params[0].value;
        const m1 = params[0].marker

        const s2n = params[1].seriesName;
        const s2v = params[1].value;
        const m2 = params[1].marker

        const year = transMonthsYears[month.toLowerCase()] || prevYear;
        prevYear = year;
        let extra = "";
        if (interval === "monthly") {
          extra = ` | ${year}`
        }
        return `
          ${month}${extra}<br />
          ${m1} ${s1n}: <b>${s1v}</b><br/>
          ${m2} ${s2n}: <b>${s2v}</b><br/>
        `;
      }
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          color: "rgba(67, 87, 133, .09)",
        },
      },
      axisLine: {
        lineStyle: {
          color: "rgba(67, 87, 133, .09)",
        },
      },
      axisLabel: {
        fontSize: 10,
        color: "#8e9cad",
        formatter: function(value) {
          if (value >= 1000000000)
            return `${+parseFloat(value / 1000000000).toFixed(1)} B`;
          if (value >= 1000000)
            return `${+parseFloat(value / 1000000).toFixed(1)} M`;
          if (value >= 1000) return `${+parseFloat(value / 1000).toFixed(1)} K`;
          return value;
        },
      },
    },
    series: chartdata,
    color: ["#ef6430", "#2205bf"],
    dataZoom: []
  };

  return option;
};

export default getOptions;
